  .grid {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .grid > div {
    width: 100%;
    /* max-width: 750px; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .grid > div > img {
    max-width: 100%;
    max-height: 100%;
  } 

  .grid-item {
    width: 50%;
    margin: 4px;
    border-radius: 4px;
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
    box-shadow: hwb(206deg 24% 74% / 20%) -1px 2px 5px 1px, rgb(60 64 67 / 15%) 0px 1px 3px 1px;
    min-width: 250px;
    min-height: 250px;
    cursor: pointer;
  }
  
  .card-container {
    max-width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .card-item {
    box-shadow: hwb(206deg 24% 74% / 20%) -1px 2px 5px 1px, rgb(60 64 67 / 15%) 0px 1px 3px 1px;
    max-width: 100%;
    min-width: 100%;
    border-radius: 26px;
    margin-bottom: 10px;
    cursor: pointer;
  }

   .grid-item:hover{
    cursor: pointer;
  }

  .ad-video{
    width: 100%;
    height: 414px;
    display: flex;
    justify-content: center;
  }

  .ad-large{
    width: 100%;
    border-radius: 20px;
    display: flex;
    justify-content: center; 
    margin-top: 25px;
    margin-bottom: 20px;
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  }

  .ad-large-item {
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(17,21,28,0.96) !important;
    color: white !important;
    overflow: hidden;
    box-shadow: hwb(206deg 24% 74% / 20%) -1px 2px 5px 1px, rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  }

  .ad-large-item > img {
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
  }

  @media only screen and (max-width: 980px) {
    /* .ads-large img{
      max-width: auto;
      width: 95%; 
    } 
     */
    .ad-large {
      /* max-height: 75px; */
      width: 100%;
    }

    .grid-item {  
      min-width: 64px;
      min-height: 64px;
    }

  }