.cam-container {
  max-width: 100%;
  min-height: 605px;
  margin-top: 15px;
  margin-bottom: 15px;
  min-width: 100%;
  width: 1000px;
  position: relative;
  height: fit-content;
  justify-content: center;
  margin-bottom: 15px;
  background-color: rgb(0, 0, 0);
  border-radius: 26px;  
  overflow: hidden;
  /* box-shadow: hwb(206deg 24% 74% / 20%) -1px 2px 5px 1px, rgb(60 64 67 / 15%) 0px 1px 3px 1px; */
  }

  .cam-video {
    width: 100%;    
    position: relative;    
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
 
  .cam-header {
    top: 0px;
    width: 100%;
    height: fit-content;
    border-top-left-radius: 26px;
    border-top-right-radius: 26px;
    font-family: "OpenSans Regular";
    background-color: #ffffff;
  }

  .cam-name-desc,.cam-desc-1{
    text-align: center;
    font-size: 1.2em;
    color: #ffffff;
    display: flex;
    max-width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    width: -webkit-fill-available;
    justify-content: space-between;
    align-items: center;
    /* height: fit-content; */
  }

  .cam-name-desc{
    top: 12%;
  }

  .cam-desc-1{
    top: 2%;
  }

  .cam-name-street {
    /* display: inline-flex; */
    /* width: 33.33%; */
    font-size: 20px;
    align-items: center;
    padding-left: 2.5vw;
    font-weight: bold;
  }

  .cam-name-location{
    width: 100px;
    height: 70px;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    background-color: white;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .cam-name {
    /* display: inline-flex; */
    /* width: 33.33%; */
    font-size: 20px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10%;
    /* background-color: #292929; */
    /* height: fit-content; */
  }

  .cam-ads{
    background-color: #72000052;
    height: 50px;
    color: white;
    text-align: center;
    font-size: 24px;
  }
  
  
  /* .cam_name{
    position: absolute;
    height: 50px;
    width: 100%;
    background-color: black;
    opacity: 0.6;
    color: white;
    top:0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: fit-content;
  } */

  #remoteVideo{
    border-radius: 4px;
  }

  @media only screen and (max-width: 980px) {
    .cam_video {
      width: 100%;
      height: auto;
      max-width: auto; 
      background-color: rgb(0, 0, 0);
    }
    .cam_grid_item {
      min-width: 64px;
      min-height: 64px;
    }
    .cam-container{
      max-width: 100%;
      max-height: 600px;
      min-width: 349px;
      min-height: 270px;
      width: 100%;
    }
    .cam-name{
      padding-right: 10%;
      font-size: 2.5vw;
      font-weight: bold;
    }

    .cam-desc-1{
      font-size: 2.5vw;
      padding-left: 5%;
    }

    /* .cam-name-desc{
      top: 5.8vh;
    } */

    .cam-name-street {
      font-size: 2.5vw;
    }
  

  }

  @media only screen and (max-width: 480px) {
    .cam_video {
      width: 100%;
      height: auto;
      max-width: auto; 
      background-color: rgb(0, 0, 0);
    }
    .cam-container{
      max-width: 100%;
      max-height: 600px;
      min-width: 349px;
      min-height: 270px;
      width: 100%;
    }
    .cam-name{
      padding-right: 10%;
      font-size: 2.5vw;
    }
    .cam-name-desc{
      top: 27px;
    }
    .cam-name-street {
      font-size: 2.5vw;
    }

    .cam-desc-1{
      font-size: 2.5vw;
      padding-left: 5%;
    }

  }

  @media only screen and (max-width: 380px) {
    .cam-name-desc{
      top: 27px;
    }
    .cam-desc-1{
      font-size: 2.5vw;
      padding-left: 5%;
    }
  }