.accordion {
    background-color: #ffffff;
    color: #000000;
    cursor: pointer;
    padding: 18px;
    max-width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 22px;
    font-weight: normal;
    transition: 0.4s;
    display: flex;
    align-items: center;
    font-family: "Gotham Light";
    border-bottom: 1px solid #f2f2f2;
  }
  
  .active, .accordion:hover {
    background-color: #f2f2f2;
  }
  
  /* .accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
    margin-left: 5px;
  } */
  
  /* .active:after {
    content: "\2212";
  } */
  
  .panel {
    padding-left: 20px;
    padding-right: 20px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
   
  }

  .item-cat{
    color: white;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .card-tittle {
      padding-top: 18px;
      text-align: left;
      letter-spacing: 0px;
      color: #E62137;
      opacity: 1;
      padding-bottom: 10px;
      font-size: 26px;
      font-family: "Gotham Black";
  }

  .card-row{
    font-weight: normal;
    font-family: "Gotham Light";
    height: auto;
  }
  .card-social {
    display: inline-flex;
    align-items: center;
    margin: 5px;
    cursor: pointer;
  }

  .Link-Color {
    color: black;
  }
  .card-line {
    border-bottom: 1px solid #f2f2f2;
  }