.border_wait{
    width: 100%;
    height: auto;
    text-align: center;
  }

  .border_wait p {
    font-size: 20px;
    color: black;
  }

  .Wait-Container{
    width: 100%;
    max-height: 100%;
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .Wait-item {
    width: 25%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    min-width: 150px;
    display: inline-flex;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 10px;
  }

  .Wait-Circle {
    max-width: 22px;
    padding: 13px;
    background-color: #0ea83c;
    color: white;
    display: flex;
    align-items: center;
  }

  .Wait-Desc {
    padding: 15px 0px;
    text-align: left;
    display: grid;
    margin-left: 1rem;
  }

  .Wait-Desc-title {
    font-size: 15px;
  } 
  
  .Wait-Desc-time {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center
  } 
  
  .Wait-Desc-lane {
    font-size: 12px;
  }

  .Border-Notice{
    padding: 10px;
    color: rgb(0, 0, 0);
  }

  .center{
    display: flex;
    align-items: center;
    justify-content: center
  }

  @media only screen and (max-width: 980px) {
    .Wait-Container {
      display: block;
    }
    .Wait-item {
      max-width: 100%;
      width: 100%;
      margin-right: 0px;
      margin-bottom: 10px;
    }

  }