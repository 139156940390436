.desc_ubicacion{
    width: 100%;
    text-align: center;
  }

  .desc_ubicacion > div {
    font-size: 1.5em;
    margin-top: 15px;
  }  
  
  .desc_ubicacion h1 {
    font-size: 3.3em;
    color: black;
  }

  div .desc_ubicacion, .desc_ubicacion p {
    padding-top: 110px;
    padding-bottom: 10px;
  }


  @media only screen and (max-width: 980px) {
    .desc_ubicacion h1 {
      font-size: 2.3em;
    }
    .desc_ubicacion > div {
      font-size: 1.3em;
    } 
  }