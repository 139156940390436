.header {
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: center;
  transition: 0.2s ease-out;
}

.App-logo {
  height: 7vmin;
}

* {
  text-decoration: none;
  list-style: none;
  margin: 0px;
  padding: 0px;
  outline: none;
}

input[type=checkbox], .toggle-menu{display:none;}

section {
  width: 100%;
  margin: 0px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between; 
  max-height: 100%;
} 

header {
  max-width: 100%;
  width: 100%;
  /* padding: 10px; */
  background-color: #ffffff;
  position: fixed;
  top: -1px;
  z-index: 1;
  height: 90px;
  /* box-shadow: 0 0 0.1em 0px rgb(0 0 0 / 27%); */
}

/* Icon 3 */

.toggle-menu {
  /* display: table; */
   position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

.toggle-menu span {
  display: block;
  position: absolute;
  height: 5px;
  width: 66%;
  background: #ffffff;
  border-radius: 9px;
  opacity: 1;
  left: 9px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}



input[type=checkbox]~.toggle-menu>span:nth-child(1) {
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

input[type=checkbox]~.toggle-menu>span:nth-child(2){
  top: 26px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center
}

input[type=checkbox]~.toggle-menu>span:nth-child(3) {
  top: 35px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

input[type=checkbox]:checked~.toggle-menu>span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 10px;
  left: 16px;
}

input[type=checkbox]:checked~.toggle-menu>span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

input[type=checkbox]:checked~.toggle-menu>span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 39px;
  left: 15px;
}



nav {
  width: auto;
  font-weight: 600;
}

nav ul {
  display: table;
}

nav ul li {
  float: left;
}

nav ul li:last-child {
  padding-right: 0px;
}

nav ul li a {
  color: #000000;
  font-size: 1.1em;
  padding: 25px;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.5s ease 0s;
}

nav ul li a:hover {
  transition: all 0.5s ease 0s;
  box-shadow: 0px 3px 0em 0px rgb(215 0 0);
}

nav ul li a i {
  padding-right: 10px;
  color: #3f3f3f;
  transition: all 0.5s ease 0s;
}

/* .toggle-menu ul {
  display: table;
  width: 25px;
}

.toggle-menu ul li {
  width: 100%;
  height: 3px;
  background-color: #ffffff;
  margin-bottom: 4px;
}

.toggle-menu ul li:last-child {
  margin-bottom: 0px;
} */

.content {
  display: table;
  margin-bottom: 60px;
  width: 900px;
}

footer {
  display: table;
  padding-bottom: 30px;
  width: 100%;
}

.contact-opt {
  color: white;
    justify-content: center;
    background-color: #ff0000;
    display: inline-flex;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
}


@media only screen and (max-width: 1299px) {

  .App-logo {
    height: 66px;
  }

  header {
    /* padding-top: 20px; */
    padding-bottom: 2px;
    padding-left: 0px;
    padding-right: 0px;
  }

  header.isSticky > section > div {
    justify-content: center;
    display:inline-flex;
    transition: 0.6s ease-out;
    transform: scale(1.2);
    position: relative;
    padding-top: 5px;
  }
  
  input[type=checkbox] {
    position: absolute;
    top: -9999px;
    left: -9999px;
    background: none;
  }

  input[type=checkbox]:fous {
    background: none;
  }

  .toggle-menu {
    background-color: #000000;
    display: block;
    cursor: pointer;
    /* padding-top: 12px; */
    z-index: 1;
    /* padding-top: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    padding-left: 15px; */
    min-width: 61px;
    min-height: 62px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  input[type=checkbox]:checked~nav {
    display: block;
  }

  nav {
    display: none;
    position: absolute;
    top: 80px;
    background-color: #ffffff;
    padding: 0px;
    z-index: 99;
    width: 75%;
    border-radius: 10px;
    border-color: #ababab;
    border-style: solid;
    border-width: 1px;
  }

  nav ul {
    width: 100%;
  }

  nav ul li {
    float: none;
    padding: 0px;
    width: 100%;
    display: table;
  }

  nav ul li a {
    color: #3f3f3f;
    font-size: 15px;
    padding: 10px 20px;
    display: block;
    border-bottom: 1px solid rgba(225, 225, 225, 0.1);
  }

  nav ul li a i {
    color: #fde428;
    padding-right: 13px;
  }
}

@media only screen and (max-width: 980px) {
  .content {
    width: 90%;
  }
  section {
    margin-top:12px;
  }
}

/* @media only screen and (max-width: 568px) {
  h1 {
    padding: 25px 0px;
  }
} */
