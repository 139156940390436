@font-face {
  font-family: "OpenSans Bold";
  src: url("./res/fonts/Open_Sans/static/OpenSans/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "OpenSans Regular";
  src: url("./res/fonts/Open_Sans/static/OpenSans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "OpenSans Extra Bold";
  src: url("./res/fonts/Open_Sans/static/OpenSans/OpenSans-ExtraBold.ttf");
}

@font-face {
  font-family: "Gotham Black";
  src: url("./res/fonts/Gotham-Font/Gotham-Font/Gotham-Black.otf")format('truetype');
}

@font-face {
  font-family: "Gotham Light";
  src: url("./res/fonts/Gotham-Font/Gotham-Font/Gotham-Light.otf")format('truetype');
}

@font-face {
  font-family: "Gotham Medium";
  src: url("./res/fonts/Gotham-Font/Gotham-Font/Gotham-Bold.otf")format('truetype');
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Gotham Black";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
}

button {
  background-color: white;
  color: black;
  border-radius: 30px;
  padding: 13px;
  width: 139px;
  border-style: solid;
  border-color: black;
  font-size: 1em;
  cursor: pointer;
}

button:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

h1, h2 {
   margin: 2%; 
  /* padding: 15px;
  font-size: 2em;
  color: rgb(51 51 51 / 90%)  */
}

/* p {
  margin: 0%;
  font-size: 1em;
} */

.container {
  width: 100%;
  background-color: white;
  top: 100px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.container-content {
  max-width: 1000px;
  padding:15px;
}

.container-column {
 display: flex;
 margin-top: 5px;
 overflow: hidden;
 max-width: 100%;
}

.container-foot {
  /* font-family: "Gotham Light"; */
  color: white !important;
  background-color: #1D1D1B;
  max-width: 100%;
  height: 150px;
  font-size: 13px !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: center;
  align-items: center;
}

.container-foot > span {
  /* font-family: "Gotham Light"; */
  color: white !important;
  font-size: 13px !important;
}

.container-social {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.item-social{
  color: white;
  width: 60px;
  height: 60px;
  background-color: red;
  border-radius: 30px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* .column{
  padding: 5px;
} */

*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@-webkit-keyframes circle {
from {
  -webkit-transform: scale(1);
          transform: scale(1); }

to {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
  opacity: 0; } }

@keyframes circle {
from {
  -webkit-transform: scale(1);
  transform: scale(1);
  }

to {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  opacity: 0;
  } }

  /* SPINER */

.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}